// This file exports the ProfilePage

// import modules
import { useState } from 'react';

// import styles
import './profile.scss'

// Import assets
import AvatarPlaceholder from '../../assets/images/placeholder.png';
import BannerPlaceholder from '../../assets/images/banner-default.png';

// Code for the ProfilePage
const ProfilePage = () => {
    const [avatar] = useState();

    // All rendered content
    return(
        <main id='Profile'>
            {/* Banner */}
            <div className="profile-banner">
                <img src={BannerPlaceholder} alt="Banner" />
            </div>
            {/* Avatar */}
            <div className="avatar-container">
                <img src={avatar ? avatar : AvatarPlaceholder} alt="Avatar" />
            </div>
            {/* Header Div */}
            <div className="header-container"></div>
            {/* Contact Div */}
            <div className="contact-container"></div>
            {/* Main content div */}
            <div className="main-content-container"></div>
        </main>
    )
}

// Export ProfilePage
export default ProfilePage;
