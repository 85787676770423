// This file exports the Question Section for the homepage

// import modules
import { useState } from "react";
import Question from "./question";
import { motion, LayoutGroup } from "framer-motion";

// Import Styles
import "./questions.scss";

// Import Assets?
import QuestionList from "./question-list.json";

// Code for the Question Section
const QuestionSection = () => {
  // State
  const [isActive, setActive] = useState();

  // All rendered content
  return (
    <section id="questions">
      <div className="inner-container">
        <h5>&#x2022; Questions</h5>
        <h2>FAQ</h2>
        <div className="question-list-container">
          <LayoutGroup>
            {QuestionList.map((i) => {
              return <Question layoutId='questions' data={i} open={true} isActive={isActive} />;
            })}
          </LayoutGroup>
        </div>
      </div>
    </section>
  );
};

// Export QuestionSection
export default QuestionSection;
