// @desc: MobileNavigation component displayed at specific viewport width

// Imported modules
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import {NavLink as Link } from 'react-router-dom';

// Imported Styles
import 'bootstrap/dist/css/bootstrap.min.css'

// Component code
const MobileNavigation = () => {
  // All Rendered content
  return (
    <Navbar bg="light" expand="lg" className='mobile-nav'>
      <Container className='nav-bg'>
        <Navbar.Brand href="#home">Project Gate</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto nav-bg">
            <Link to='/'>Home</Link>
            <Link to='/myprojects'>Projects</Link>
            <Link to='/profile'>Profile</Link>
            <Link to='/settings'>Settings</Link>
            <Link to='/notifications'>Notifications</Link>
            <Link to='/logout'>Logout</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

// Export component
export default MobileNavigation
