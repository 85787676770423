// This file exports the project list component

// Import modules
import ProjectContainer from './ProjectContainer'

// Import styles
import './project-list.scss'

// ProjectList component
const ProjectList = () => {
  // Mock data
  const mockProjectData = [
    {
      id: 0,
      title: 'Project Gate',
      info:
        'Technologies used: HTML, CSS, React.js, Node.js, Express.js, Axios, MongoDB, Mongoose',
      description:
        'Common platform for project seekers and project managers in theFIRMA that is aimed to upgade current project management environment in theFIRMA. theFIRMA memebers can create their own profiles, update their individual skills, interests and look/apply for projects within the project office.',
      tags: ['internal', 'web', 'open positions'],
    },
    {
      id: 1,
      title: 'Project Management',
      info: 'We are looking for project managers for our projects.',
      description:
        "Many of theFIRMA's projects need a project manager. Several students needed",
      tags: ['external', 'project', 'open positions'],
    },
  ]

  // All rendered content
  return (
    <main id="ProjectList">
      {mockProjectData.map((item, key) => {
        return <ProjectContainer data={item} key={key} />
      })}
    </main>
  )
}

// Export projectList
export default ProjectList
