// This file exports the footer component

// Import Modules
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop } from '@fortawesome/free-solid-svg-icons'

// Import Styles
import './footer.scss'

// Import Assets
import FirmaLarge from '../../assets/images/firmaLarge.png'
import Tuas from '../../assets/images/tuas.png';

// Footer Component
const Footer = () => {
  // All rendered content
  return (
    <footer id="Footer">
      <div className="footer-inner-container">
        <div className="left">
          <img src={FirmaLarge} alt="theFIRMA logo" />
        </div>
        <div className="middle">
          <h4> theFIRMA Office</h4>
          <p>Turku University of Applied Sciences</p>
          <p>Joukahaisenkatu 3-5, 20520 Turku</p>
          <br />
          <div className="webLink">
            <FontAwesomeIcon icon={faDesktop} transform="grow-1" />
            <a href='https://thefirma.fi/' target={"_blank"} rel="noreferrer">https://thefirma.fi/</a>
          </div>
        </div>
        <div className="right">
        <img src={Tuas} alt="Tuas logo" />
        </div>
      </div>
    </footer>
  )
}

// Export component
export default Footer
