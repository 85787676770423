// The individual question component

// Import modules

// Code for the question component
const Question = ({ open, data }) => {
  // All rendered content
  return (
    <>
      {!open ? (
        <div 
        className="question-container closed" 
        key={data.id} 
        onClick={''} >
          <h3 className="question-header closed">{data.header}</h3>
        </div>
      ) : (
        <div className="question-container open" key={data.id}>
          <h3 className="question-header open">{data.header}</h3>
          <p className="answer-content">{data.content}</p>
        </div>
      )}
    </>
  );
};

// Export Question component
export default Question;
