// This file exports the ProjectContainer for the project list

// Import Modules

// Import Styles
import './project-container.scss'

// Code for ProjectContainer
const ProjectContainer = ({ data, key }) => {
  // All rendered content
  return (
    <div className="project-container" key={key}>
      <div className="inner-project-container">
        <h4>{data.title}</h4>
        <div className="tag-list">
          {data.tags.length > 0
            ? data.tags.map((i, k) => {
                return (
                  <div className={`tag ${i}`}>
                    <p key={k}>{i}</p>
                  </div>
                )
              })
            : ''}
        </div>
        <div className="project-info">
          <div className="text-box">
            <h5>Info:</h5>
            <span className="span-text">{data.info}</span>
          </div>
          <div className="text-box">
            <h5>Description:</h5>
            <span className="span-text">{data.description}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
//export ProjectContainer Component
export default ProjectContainer
