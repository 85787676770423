// Website homepage that explains the entire premise of the application

// Import modules
import { motion } from 'framer-motion'

// Import styles
import "./home.scss";

// Import assets
import Tuas from "../../assets/images/tuas-gray.png";
import theFIRMA from "../../assets/images/firma-gray.png";
import { WebFooter, UnderDevelopment } from "../../Components";
import { QuestionSection } from '../../static-site';

// Home page component
const HomePage = () => {
  // All rendered content
  return (
    <div id="HomePage">
      {/* Conditional: UnderDevelopment notice */}
      <UnderDevelopment />
      {/* Top of the page */}
      <header>
        <div className="header-container">
          <div>Project Gate</div>
          <div className="logos">
            <img className="tuas-logo" src={Tuas} alt="tuas logo" />
            <img className="theFIRMA-logo" src={theFIRMA} alt="theFIRMA logo" />
          </div>
          <div className='login__btn-container'>
            <button className='login__btn'>
              Log in
            </button>
          </div>
        </div>
      </header>
      {/* Main content of the page */}
      <main>
        <motion.div
          className="hero-container"
        >
          <motion.h1
          initial={{ y: "200px" }}
          animate={{ y: "0px" }}
          transition={{ duration: '0.4', ease:'easeInOut'}}>
            <span>Linking</span> students with projects
          </motion.h1>

          <motion.p
            initial={{ y: "220px", opacity: 0}}
            animate={{ y: "0", opacity: 1}}
            transition={{ delay: '0.2', duration: '0.4', ease: 'easeInOut'}}
          >
            The purpose of project gate is to provide students with a catalog of
            all the on-going projects within theFIRMA. For best use of
            resources, students will be matched with projects that they are
            interested in, and that could use their set of skills.
          </motion.p>
          <div className="action-buttons">
            <motion.button
            initial={{scale: 0}}
            animate={{scale: 1}}
            transition={{ delay: 0.4, duration: 0.3, ease: 'easeInOut'}}
            className="sign-up__btn">Sign up</motion.button>
            <motion.button
            initial={{scale: 0}}
            animate={{scale: 1}}
            transition={{ delay: 0.5, duration: 0.3, ease: 'easeInOut'}}
            className="lrn-more__btn">Learn more</motion.button>
          </div>
        </motion.div>
        <section>
          <h5> &#x2022; Info</h5>
          <h2>Features</h2>
        </section>
        <QuestionSection />
        <section id="news">
          <div className="inner-container">
            <h5> &#x2022; Recent updates</h5>
            <h2>News</h2>
          </div>
        </section>
      </main>
      <WebFooter />
    </div>
  );
};

// Export page
export default HomePage;
