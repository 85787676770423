// @desc: Navigation component

// Importing modules
import { useEffect, useState } from 'react'
import { NavLink as Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHome,
  faFolderOpen,
  faGear,
  faUser,
  faBell,
  faArrowRightFromBracket,
} from '@fortawesome/free-solid-svg-icons'

// Importing styles
import './nav.scss'
import MobileNavigation from './MobileNavigation'

// Navigation component
const Navigation = () => {
  // States
  const [viewport, setViewport] = useState(window.innerWidth)
  useEffect(() => {
    window.addEventListener('resize', () => {
      setViewport(window.innerWidth)
    })
  }, [viewport])
  // All rendered content
  return (
    <nav id="navigation">
      {viewport >= 450 && (
        <div className="inner-nav-container">
          <div className="navContainer nav-left">
            <Link to="/">
              <div className="icon-container Home-icon" title="Home">
                <FontAwesomeIcon icon={faHome} transform="grow-1" />
              </div>
            </Link>
            <Link to="/myprojects">
              <div className="icon-container Projects-icon" title="Projects">
                <FontAwesomeIcon icon={faFolderOpen} transform="grow-1" />
                <p>Projects</p>
              </div>
            </Link>
          </div>

          <div className="navContainer nav-right">
            <Link to="/profile">
              <div className="icon-container Profile-icon" title="Profile">
                <FontAwesomeIcon icon={faUser} transform="grow-1" />
              </div>
            </Link>
            <Link to="/settings">
              <div className="icon-container Settings-icon" title="Settings">
                <FontAwesomeIcon icon={faGear} transform="grow-1" />
              </div>
            </Link>
            <Link to="/notifications">
              <div
                className="icon-container Notifications-icon"
                title="Notifications"
              >
                <FontAwesomeIcon icon={faBell} transform="grow-1" />
              </div>
            </Link>
            <Link to="/logout">
              <div className="icon-container LogOut-icon" title="Log Out">
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket}
                  transform="grow-1"
                />
              </div>
            </Link>
          </div>
        </div>
      )}
      {viewport < 450 &&
      <MobileNavigation />
      }
    </nav>
  )
}

// Export component
export default Navigation
