// This file exports the footer for the static website

// Import modules

// Import styles

// Code for WebFooter
const WebFooter = () => {
  // All rendered content
  return (
    <footer className="web-footer">
      <div className="foot-container left-foot"></div>
      <div className="foot-container right-foot"></div>
    </footer>
  );
};

// Export component
export default WebFooter;
