// This file contains logic for website and webapp
// Import modules
import { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import './App.scss'
import { Footer, Navigation, ProjectList } from './Components'
import { HomePage, ProfilePage, ProjectsPage} from './Pages';
import { NoLogNav } from './static-site';

function App() {
  // state
  const [user] = useState({ username: '', sessionToken: '' })
  // All rendered content
  return (
    <Router>
      <div className="App">
        {user.username.length > 0 && (
          <>
            <Navigation />
            <Routes>
              {/* <Route path="/" element={<Dashboard user={'Ville'} />} /> */}
              <Route path="/" element={<HomePage user={'Ville'} />} />
              <Route path="/projects" element={<ProjectsPage />} />
              <Route path="/myprojects" element={<ProjectList />} />
              <Route path="/profile" element={<ProfilePage />} />
            </Routes>
            <Footer />
          </>
        )}

        {user.username.length <= 0 && (
          <>
          <NoLogNav />
          <HomePage />
          </>
        )}
      </div>
    </Router>
  )
}

export default App
