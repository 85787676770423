// This file exports a component that gets displayed to everyone on first page load

// import Modules
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

// import styles
import "./underdevelopment.scss";

// Code for UnderDevelopment
const UnderDevelopment = () => {
  // State
  const [visibility, setVisibility] = useState();

  // Effect executed on first page load
  useEffect(() => {
    // check whether an item is stored in localStorage
    const x = window.localStorage.getItem("PG-notice");

    // If first visit on the site, display notice
    // else don't show the notice
    if (!x) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  }, []);


  // Write to local storage that user has seen the notice
  // that the site is underDevelopment
  const  Noted = (e) => {
    // Prevent any default behaviors
    e.preventDefault();

    // Set localStorage to a variable
    const x = window.localStorage;

    // Store noted to localStorage
    x.setItem('PG-notice', 'true');
    setVisibility(false);
  }

  // If user hasn't seen the notice, display notice
  // otherwise don't render the component
  if (visibility) {
    return (
      <motion.div className="viewer-notice">
        <motion.div
        //   style={{ height: "300px" }}
          initial={{ y: "-120px" }}
          animate={{ y: "0" }}
          exit={{ y: "120px" }}
          transition={{ duration: "0.5s", ease: "easeInOut"}}
          className="inner-container"
        >
          <h5>&#x2022; NOTICE</h5>
          <h2>This site is under Development</h2>
          <p>
            The site is currently in pre-release. This means that we are making
            changes to the site continously. If you wish to follow our progress,
            check back daily to see our lates changes.
          </p>
          <button className="accepted" onClick={(e) => Noted(e)}>
            Understood!
          </button>
        </motion.div>
      </motion.div>
    );
  }
};

// Export UnderDevelopment component
export default UnderDevelopment;
