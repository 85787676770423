// Exports the project list page

// Import Modules

// Import Styles

// Project list page
const ProjectsPage = () => {

    // All rendered content
    return (
        <main id="projects">
            <h1>Projects</h1>
        </main>
    )
}

// Export page
export default ProjectsPage;
