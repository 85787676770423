// This is the navigation for the website before loggin onto the app

// Import modules
import { motion, LayoutGroup } from "framer-motion";

// Import styles
import "./nolognav.scss";

// Code for NoLogNav
const NoLogNav = () => {
  // All rendered content
  return (
    <nav id="NoLogNav">
      <LayoutGroup>
        <div className="inner-container">
          <p className="nav-link"> Home </p>
          <p className="nav-link"> Features </p>
          <p className="nav-link"> Questions </p>
          <p className="nav-link"> News </p>
        </div>
      </LayoutGroup>
    </nav>
  );
};

// Export NoLogNav
export default NoLogNav;
